import React from 'react'
import Layout from '../components/layout'
import Range from '../components/range'
import Slider from '../components/slider'
import BlockFooter from '../components/blockFooter'
import Seo from '../components/seo'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Helmet } from 'react-helmet'

export const query = graphql`
  {
    strapiHomepage {
      mainBanner {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      middleBanner {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      endBanner {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      mainBannerMobile {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      middleBannerMobile {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      endBannerMobile {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    latest: allStrapiProduct(filter: {new: {eq: true}}) {
      nodes {
        id
        slug
        model
        searchTitle
        url
        images {
          name
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    featured: allStrapiProduct(filter: {featured: {eq: true}}) {
      nodes {
        id
        slug
        url
        model
        searchTitle
        images {
          name
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default function Index() {
  const data = useStaticQuery(query)
  const latestProducts = data.latest.nodes
  const featuredProducts = data.featured.nodes
  const mainBanner = data.strapiHomepage.mainBanner
  const middleBanner = data.strapiHomepage.middleBanner
  const endBanner = data.strapiHomepage.endBanner
  const mainBannerMobile = data.strapiHomepage.mainBannerMobile
  const middleBannerMobile = data.strapiHomepage.middleBannerMobile
  const endBannerMobile = data.strapiHomepage.endBannerMobile
  return (
    <Layout>

      <Seo 
          title={'Gazelle® Tools Official Site | Products That Work As Hard As You Do®'}
          description={'Gazelle® makes high-performance professional tools and safety solutions. Explore our range of power tools, ladders, toolboxes, and safety shoes designed for the toughest conditions'}
      />
      <Helmet>
        <meta name="google-site-verification" content="2WGnFclPeMbpplipBiSM0Jab0UKBfsFPhS8VPsJVvl8" />
      </Helmet>

      {/* MAIN BANNER */}
      <Link className="hidden md:block w-full bg-primary">
        <GatsbyImage
          image={getImage(mainBanner.localFile)}
          className="w-full h-full hidden md:block"
          alt="Gazelle Industrial Promotional Banner"
        />
      </Link>
      <Link className='md:hidden'>
        <GatsbyImage
          image={getImage(mainBannerMobile.localFile)}
          className="w-full h-full md:hidden"
          alt="Gazelle Industrial Promotional Banner"
        />
      </Link>
      <Range title="Discover Our Range" />

      {/* MIDDLE BANNER */}
      <Link to="/g80231-mechanical-tool-set-188-pieces" className="hidden md:block w-full bg-secondary">
        <GatsbyImage
          image={getImage(middleBanner.localFile)}
          className="w-full h-full hidden md:block"
          alt="Gazelle Industrial Promotional Banner"
        />
      </Link>
      <Link to="/g80231-mechanical-tool-set-188-pieces" className='md:hidden'>
        <GatsbyImage
          image={getImage(middleBannerMobile.localFile)}
          className="w-full h-full md:hidden"
          alt="Gazelle Industrial Promotional Banner"
        />
      </Link>
      <Slider title="Latest Products" products={latestProducts} />

      {/* BOTTOM BANNER */}
      <Link to="/g9710-pocket-thermal-camera-256x192p" className="hidden md:block w-full bg-secondary">
        <GatsbyImage
          image={getImage(endBanner.localFile)}
          className="w-full h-full hidden md:block"
          alt="Gazelle Industrial Promotional Banner"
        />
      </Link>
      <Link to="/g9701-thermal-imager-160x120p" className='md:hidden'>
        <GatsbyImage
          image={getImage(endBannerMobile.localFile)}
          className="w-full h-full"
          alt="Gazelle Industrial Promotional Banner"
        />
      </Link>

      <Slider title="Featured Products" products={featuredProducts} />

      <BlockFooter />
    </Layout>
  )
}
